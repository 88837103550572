export const vueroutes = [
    {
        path: '/vuepage_1.1',
        name: 'vuepage_1.1',
        component: () => import('../views/PageContent/VueContent/vue1.1')
    },
    {
        path: '/vuepage_1.2',
        name: 'vuepage_1.2',
        component: () => import('../views/PageContent/VueContent/vue1.2')
    },
    {
        path: '/vuepage_1.3',
        name: 'vuepage_1.3',
        component: () => import('../views/PageContent/VueContent/vue1.3')
    },
    {
        path: '/vuepage_1.4',
        name: 'vuepage_1.4',
        component: () => import('../views/PageContent/VueContent/vue1.4')
    },
    {
        path: '/vuepage_1.5',
        name: 'vuepage_1.5',
        component: () => import('../views/PageContent/VueContent/vue1.5')
    },
]
