import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// markdown样式
import "github-markdown-css";
// 代码高亮
import "highlight.js/styles/github.css"; //默认样式

const app = createApp(App)

app.use(ElementPlus)
app.use(store).use(router)
app.mount('#app')

// createApp(App).use(store).use(router).mount('#app')
import axios from "axios";
import VueAxios from 'vue-axios'

app.use(VueAxios, axios)


let http = axios.create({
    //baseURL: 'api', //部署时这行注释掉！！！！！
    timeout: 3000
});
http.get('/add')
    .then()
    .catch(function(error) {
        // 请求失败处理
        console.log(error);
    });
http.get('/show')
    .then(response => {
        let data = response.data;
        console.log("times: " + data['visit times']);
    })
    .catch(function(error) {
        // 请求失败处理
        console.log(error);
    });
