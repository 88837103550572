<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <HomePage>
      <Main_Introduce></Main_Introduce>
    </HomePage>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import HomePage from '@/components/HomePage.vue';
import Main_Introduce from '@/components/Introduce.vue';


export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    HomePage,
    Main_Introduce
  }
}
</script>
