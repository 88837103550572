import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {vueroutes} from './vuepage'
import {weekroutes} from "@/router/weekpage";
import {crawlerroutes} from "@/router/crawlerpage";
import {langchainpage} from "@/router/langchainpage";

let rout = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/vueview',
    name: 'vueview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "VueView" */ '@/views/VueView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AboutView" */ '@/views/AboutView.vue')
  },
  {
    path: '/crawlerview',
    name: 'crawlerview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CrawlerView" */ '@/views/CrawlerView.vue')
  },
  {
    path: '/langchainview',
    name: 'langchainview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CrawlerView" */ '@/views/LangchainView.vue')
  },
  {
    path: '/emoview',
    name: 'emoview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "EmoView" */ '@/views/EmoView.vue')
  },
  {
    path: '/weekview',
    name: 'weekview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "WeekView" */ '@/views/WeekView.vue')
  },
  {
    path: '/cosplayview',
    name: 'cosplayview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    component: () => import(/* webpackChunkName: "CosplayView" */ '@/views/CosplayView.vue')
  },
]

const routes = rout.concat(vueroutes).concat(weekroutes).concat(crawlerroutes).concat(langchainpage)

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory("/"),
  routes
})

export default router
