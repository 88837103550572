<template>
    <div class="introduce">
        <h1>你好，欢迎来到这里!</h1>
        <p>这里是镜宫耀，是一个心血来潮想要搭建自己的网站的代码苦手(；′⌒`)</p>
        <p>但是勤能补拙，多练练水平总会上去的~</p>
        <p>让我们通过简单的一问一答来介绍一下这个网站吧 (*/ω＼*)</p>
        <p> </p>

        <p>问：自我介绍一下吧！</p>
        <img src="oc1.png" class="oc">
        <p>答：你好~这里是镜宫耀，叫我镜宫就好，因为在不同地方我可能叫镜宫玲、镜宫明等等……基本上是个二次元宅，ACG通吃。同时喜欢玩Cosplay，虽然水平不高但是敢于尝试。未来还会开拓更多的兴趣爱好(/≧▽≦)/</p>

        <p>问：这个网站是用来干什么的？</p>
        <p>答：这个网站主要是镜宫用来提升自己的编程水平用的（认真），以及记录自己的生活。希望在未来，当我的编程水平逐渐提高之时，我能将这个网站进行优化，做出更加精致的内容。♪(^∇^*)</p>
        
        <p>问：这个网站用了什么技术呢？</p>
        <p>答：目前用了Vue3.0 + vue-cli + webpack + node.js + ElementUI，希望在未来我能学会更多的技术，丰富这个网站！o(*￣︶￣*)o</p>

        <p>(24年9月二编：刚开始做这个网站的我好活泼……现在我好麻……刚写这个页面时我应该是大三还是大四吧，现在我已经是二战上岸研究生了，眼中尽是悲伤……)</p>
    </div>
</template>
<script>
export default { 
    name: 'Main_Introduce',
}
</script>

<style>
p {
    float: left;
    font-size: 20px;
    margin-left: 10px;
    width: 100%;
}

h1 {
    text-align: center;
}

.introduce {
    text-align:left
}

.oc {
    width: 200px;
    height: 200px;
}
</style>