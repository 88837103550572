<template>
    <div>
        <div id="top">
            <strong class="logo">Kagamiya's blog ✿</strong>
        </div>
        <div id="header" class="header">
            <el-page-header @back="goBack">
                <template #content>
                    <span class="text-large font-600 mr-3"> {{ pagename }} </span>
                </template>
            </el-page-header>
        </div>
        <div id="content" class="content">
            <div id="left_content" class="left_content">
              <Left_Menu :activeitem="this.$route.query.key"></Left_Menu>
                <!-- <el-tree indent="30" icon="none" :data="data" :props="defaultProps" @node-click="handleNodeClick" highlight-current="true"/> -->
            </div>
            <div id="right_content" class="right_content">
                <slot></slot>
                <!-- <Main_Introduce></Main_Introduce> -->
            </div>
        </div>
        <el-backtop :right="50" :bottom="100">
          <div class="topbutton">
            UP
          </div>
        </el-backtop>
    </div>
</template>

<script>
import Left_Menu from './Menu.vue';
import router from "@/router";
// import Main_Introduce from './Introduce.vue';

export default {
  name: 'HomePage',
  data() {
    return {
      // pagename: "关于我和这个博客"
    }
  },
  components: {
    Left_Menu,
    // Main_Introduce
  },
  computed: {
    pagename : function() {
      if(this.$route.query.key != "") {
        switch (this.$route.query.key) {
          case "vueview":
            return "Vue";
          case "crawlerview":
            return "爬虫";
          case "emoview":
            return "emo";
          case "cosplayview":
            return "Cosplay";
          case "weekview":
            return "随记";
          case "about":
            return "关于你"
          default:
            return "主页"
        }
      }
      else {
        return "";
      }
    }
  },
  methods: {
    goBack: function() {
        router.back();
    },
    handleNodeClick : function(data) {
      console.log(data)
    },
  }
}
</script>

<style scoped>
#top {
    background-color: rgb(30, 61, 30);
    width: 100%;
    height: 50px;
}

.logo {
    float: left;
    color: aliceblue;
    margin-left: 20px;
    margin-top: 15px;
}

.header {
    height: 100%;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}

#content {
    width: auto;
    background-color: rgb(141, 162, 125);
    height: 100%;
    display: flex;
    padding: 50px 50px;
    border-top: 1px solid rgb(150, 175, 94);
}

#left_content{
    float: left;
    /* background-color: aqua; */
    background-color:white;
    width: 20%;
    height: auto;
    padding-left: 10px;
    padding-top: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#right_content{
    float: right;
    /* background-color:aquamarine; */
    background-color:white;
    width: 70%;
    padding: 10px 60px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.topbutton {
  height: 100%;
  width: 100%;
  background-color: var(--el-bg-color-overlay);
  box-shadow: var(--el-box-shadow-lighter);
  text-align: center;
  line-height: 40px;
  color: rgb(150, 175, 94);
  border-radius: 10px;
}

img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  margin: 0;
  padding: 0;
}
</style>