<template>
    <el-row class="tac">
      <el-col>
        <el-menu
          :default-active=act
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          active-text-color="#96AF5E"
          :collapse-transition="false"
        >
          <el-menu-item index="1" @click="gotopage('/')">
            <!-- <el-icon><icon-menu /></el-icon> -->
            <span>关于我与此博客</span>
          </el-menu-item>
          <el-sub-menu index="2">
            <template #title>
              <!-- <el-icon><location /></el-icon> -->
              <span>正文</span>
            </template>
            <el-menu-item-group title="学习笔记">
              <el-menu-item index="1-1" @click="gotopage('vueview')">此网站开发</el-menu-item>
              <el-menu-item index="1-2" @click="gotopage('crawlerview')">计算机技术</el-menu-item>
              <el-menu-item index="1-6" @click="gotopage('langchainview')">Langchain</el-menu-item>
<!--              <el-sub-menu index="1-2" >-->
<!--                <template #title>兴趣所向</template>-->
<!--                <el-menu-item index="1-2-1" @click="gotopage('crawlerview')">爬虫</el-menu-item>-->
<!--              </el-sub-menu>-->
            </el-menu-item-group>
            <el-menu-item-group title="生活相关">
              <el-menu-item index="1-3" @click="gotopage('weekview')">随记</el-menu-item>
              <el-menu-item style="display: none" index="1-4" @click="gotopage('emoview')">emo</el-menu-item>
              <el-menu-item index="1-5" @click="gotopage('cosplayview')">Cosplay</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
          
          <el-menu-item index="3" @click="gotopage('about')">
            <span>关于你</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      
    </el-row>
  </template>
  
<script>
export default {
    name: 'Left_Menu',
    props: ['activeitem'],
    methods: {
        handleOpen : function(key, keyPath) {
            console.log(key, keyPath)
        },
        handleClose : function(key, keyPath) {
            console.log(key, keyPath)
        },
        gotopage : function(str) {
          this.$router.push({
            path: "/"+str,
            query: {
              key:str
            }
          });
            // console.log(str);
        }
    },
    computed : {
        act : function(){
          switch (this.activeitem) {
            case "vueview":
              return "1-1";
            case "crawlerview":
              return "1-2";
            case "emoview":
              return "1-4";
            case "cosplayview":
              return "1-5";
            case "weekview":
              return "1-3";
            case "about":
              return "3";
            case "langchainview":
              return "1-6"
            default:
              return "1";
          }
        },
    }
}
</script>
  